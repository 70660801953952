// Packages
import React from "react";

// Parts
import Column from "./column";
import { spacingOptions } from "./utils";

// Styles
import styles from "./style.module.scss";

// Types
import { IRow, TSpacing } from "./types";

export default ({
	children,
	className = "",
	id,
	spacing = "medium",
	maxColumnCount,
}: IRow) => {
	const classNameConcat = `${spacingOptions[spacing]}`;
	return (
		<div
			className={`${className} ${styles.grid_row_wrapper} ${classNameConcat}`}
			id={id}
		>
			{React.Children.toArray(children).map((item: any) => {
				const columnsMax =
					maxColumnCount || (React.Children.count(children) as TSpacing);
				const isEmpty = item.props.children.props.children === false;

				return (
					item && (
						<Column
							key={`column-${className}-${item?.props?.id}`}
							maxColumnCount={columnsMax}
							className={`${isEmpty && styles.grid_column_empty} ${
								item.props.className
							}`}
							id={item.props.id}
							spacing={item.props.spacing || spacing}
							sizes={item.props.sizes}
						>
							{item.props.children}
						</Column>
					)
				);
			})}
		</div>
	);
};
