// Packages
import React from "react";

// Components
import { Button, FeatherIconsType, PhosphorIconsType } from "@seidor/orbit-ds-core";

// Types
import { TextPosition } from "./types";

// Styles
import styles from "./styles.module.scss";

interface IBaseProps {
	image: string;
	title?: string;
	description: React.ReactNode | React.ReactNode[];
	textPosition?: TextPosition;
	isBlocked?: boolean;
}

interface IMessageBlock extends IBaseProps {
	buttonText?: never;
	buttonIcon?: never;
	buttonVariant?: never;
	buttonAction?: never;
}

interface IMessageBlockButton extends IBaseProps {
	buttonText: string;
	buttonIcon?: FeatherIconsType | PhosphorIconsType;
	buttonVariant?: "default" | "underlinedText";
	buttonAction: () => void;
}

export type MessageBlockProps = IMessageBlock | IMessageBlockButton;

/**
 * @description           [Component use case in Empty a Table]
 *
 * @example
 *
 * {data?.data?.length > 0 ? (
 *	 <Table data={data?.data} />
 *	) : (
 *  <MessageBlock
 *       image={emptyImage}
 * 	  description={[
 *			"Parece que não temos nenhuma informação por aqui... Que tal criar uma ",
 *			<b>informar ação agora? </b>,
 *			"É só clicar no botão acima.",
 *		   ]}
 *  />
 * )}
 * ------------------------------------------------------------------
 *
 * @description           [Component use case with Button]
 *
 * @example
 *
 *  <MessageBlock
 *     image={emptyImage}
 * 	description={[
 *			"Parece que não temos nenhuma informação por aqui... Que tal criar uma ",
 *			<b>informar ação agora? </b>,
 *			"É só clicar no botão acima.",
 *		   ]}
 *	    buttonText="Recarregar"
 *	    buttonIcon="refresh-ccw"
 *	    buttonAction={() => console.log("ButtonAction")}
 *  />
 * ------------------------------------------------------------------
 *
 * @description           [Component use case with Bottom text position and title]
 *
 * @example
 *
 *  <MessageBlock
 *       image={emptyImage}
 * 	  description={[
 *			"Parece que não temos nenhuma informação por aqui... Que tal criar uma ",
 *			<b>informar ação agora? </b>,
 *			"É só clicar no botão acima.",
 *		   ]}
 *       title="Vamos Começar?"
 *       textPosition="bottom"
 *  />
 */

const MessageBlock: React.FC<MessageBlockProps> = ({
	image,
	title,
	description,
	buttonText,
	buttonIcon,
	buttonVariant = "default",
	buttonAction,
	textPosition = "right",
	isBlocked = false,
}) => {
	// -------------------------------------------------
	// Style Modifiers
	// -------------------------------------------------
	const stylesModifiers = {
		wrapperBlockModifier: () => (isBlocked ? styles.wrapper_blocked : styles.wrapper),
		contentBlockModifier: () => (isBlocked ? styles.content_blocked : styles.content),
		textPositionModifier: () => {
			switch (textPosition) {
				case "bottom":
					return styles.content_text_bottom;
				default:
					return "";
			}
		},
	};
	// -------------------------------------------------
	// Render
	// -------------------------------------------------
	return (
		<section className={stylesModifiers.wrapperBlockModifier()}>
			<div
				className={`${stylesModifiers.contentBlockModifier()} ${stylesModifiers.textPositionModifier()}`}
			>
				<img src={image} alt="message icon" />
				<div className={styles.information_session}>
					{!!title && <h1>{title}</h1>}
					<p>{description}</p>
					{!!buttonText && (
						<Button
							label={buttonText}
							icon={buttonIcon}
							onClick={buttonAction}
							variant={buttonVariant}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

export default MessageBlock;
