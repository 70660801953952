// Interfaces
import { FetchConfig } from "./types";

export default (config?: FetchConfig) => {
	if (!config) return;

	if (config.controllerName && typeof config.controllerName !== "string") {
		throw new Error("fetch plugin config controllerName must be a string");
	}

	if (config.endpoint && typeof config.endpoint !== "string") {
		throw new Error("fetch plugin config endpoint must be a string");
	}

	if (config.whitelist && !Array.isArray(config.whitelist)) {
		throw new Error("fetch plugin config whitelist must be an array of strings");
	}

	if (config.blacklist && !Array.isArray(config.blacklist)) {
		throw new Error("fetch plugin config blacklist must be an array of strings");
	}

	if (config.whitelist && config.blacklist) {
		throw new Error("fetch plugin config cannot have both a whitelist & a blacklist");
	}
};
