// Package
import createModel from "../../../utils/factory/model";

// Interface
import IGeneralLocationModel from "./types";

const baseUrl = "/generaldataservice/api";

export default createModel<IGeneralLocationModel>({
  name: "general.location",
  endpoint: {
    general: `${baseUrl}/addresses`,
  },

  effects: () => ({
    async getUF() {
      return {
        endpoint: `${baseUrl}/common/state/`,
      };
    },
    async getCity(uf: string) {
      return {
        endpoint: `${baseUrl}/common/city?uf=${uf}`,
      };
    },
  }),
});
