// Packages
import React from "react";

// Images
import logo from "assets/svg/logoSimple.svg";
import logoInverted from "assets/svg/logoInverted.svg";
import sap from "assets/svg/logosSeidor.svg";
import sapInverted from "assets/svg/logosSeidorInverted.svg";

// Style
import style from "./style.module.scss";

// Types
import { IFooterProps } from "./types";

const Footer = ({ variant }: IFooterProps) => {
  return (
    <footer
      className={`${style.footerBackground} ${
        variant === "simple" && style.footerBackgroundSimple
      }`}
    >
      <div className={style.footerContainer}>
        <div
          className={
            variant === "simple"
              ? style.footerContentSimple
              : style.footerContent
          }
        >
          <img
            src={variant === "simple" ? logo : logoInverted}
            alt="Logo Orbit"
          />
          <img
            src={variant === "simple" ? sap : sapInverted}
            alt="Logo Seidor"
          />
        </div>

        <div
          className={`${style.footerNavigation} ${
            variant === "simple" && style.footerNavigationSimple
          }`}
        >
          <p>
            Fundada em 2019 o Orbit é uma Startup de Inovação que nasceu dentro
            da Seidor Brasil, a maior consultoria de SAP da América Latina, e
            atualmente ajudar grandes empresas a gerenciarem o grande fluxo de
            informações provenientes do ERP e a conciliar estes dados com os
            órgãos governamentais.
          </p>
          <img src={sap} alt="Logo Seidor" />
          {variant !== "simple" && (
            <>
              <div>
                <h2>CONHEÇA NOSSOS MÓDULOS</h2>
                <ul>
                  <li>Inbound</li>
                  <li>Outbound</li>
                  <li>Retail</li>
                  <li>Recursos Humanos</li>
                  <li>Serviços</li>
                  <li>Fiscal Brasil</li>
                  <li>Fiscal Colômbia</li>
                </ul>
              </div>
              <div>
                <h2>MAPA DO SITE</h2>
                <ul>
                  <li>Sobre Nós</li>
                  <li>Trabalhe Conosco</li>
                  <li>Contato</li>
                  <li>Política de Privacidade</li>
                  <li>Política de Cookies</li>
                </ul>
              </div>
            </>
          )}
        </div>

        <div className={style.footerContent}>
          <p>Feito com ❤️ pelo time Orbit Brasil.</p>
          <p>Copyright © 2022. Todos os direitos reservados</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
