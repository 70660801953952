import outbound from "./modules/outbound";
import components from "./components";
import general from "./general";

export default {
  translations: {
    general,
    outbound,
    components,
  },
};
