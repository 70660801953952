// Packages
import React, { useState, Suspense, useEffect } from "react";
import { withRouter } from "react-router-dom";

import "./App.css";

// Parts
import Routes from "utils/routes";
import Header from "components/header";
import Footer from "components/footer";

function App() {
  // States
  const [scroll, setScroll] = useState(0);

  const addScrollListener = () => {
    const onScroll = () => setScroll(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  };

  useEffect(() => {
    addScrollListener();
  }, []);

  // -------------------------------------------------
  // Render
  // -------------------------------------------------

  return (
    <>
      <Suspense fallback="">
        <div className="mainContainerHeader">
          <Header scroll={scroll} />
        </div>
        <div className="mainContainerContent">
          <Routes />
        </div>
        <div className="mainContainerFooter">
          <Footer variant="simple" />
        </div>
      </Suspense>
    </>
  );
}

export default withRouter(App);
