// Packages
import React, { useState, useEffect } from "react";

// Styles
import style from "./style.module.scss";

// Hooks
import { useIndex } from "models/outbound/counties/hooks";

const OutboundView = () => {
  // States
  const [scroll, setScroll] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(4);

  // Hooks
  const dataCounties = useIndex({
    page: currentPage,
    size: limit,
    sort: {
      order: "desc",
      param: "created_at",
    },
  });

  const addScrollListener = () => {
    const onScroll = () => setScroll(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  };

  useEffect(() => {
    addScrollListener();
  }, []);

  return <div className={style.universeBackground}>teste 2</div>;
};

export default OutboundView;
