/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

// Packages
import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Interfaces
import { IRootState, Dispatch } from "../../../models/store";
import { IndexReturn, ShowReturn, IndexOptions } from "./types";

export function createGeneralHook(modelName: string) {
	return function useCreate() {
		// -------------------------------------------------
		// Properties
		// -------------------------------------------------

		// store
		const dispatch = useDispatch<Dispatch>();

		// -------------------------------------------------
		// Callbacks
		// -------------------------------------------------

		const onCreate = React.useCallback(
			async (data) => {
				return (dispatch as any)[modelName].create(data);
			},
			[dispatch]
		);

		const onUpdate = React.useCallback(
			async (id, data) => {
				return (dispatch as any)[modelName].update({ id, data });
			},
			[dispatch]
		);

		const onDelete = React.useCallback(
			async (id) => {
				return (dispatch as any)[modelName].delete(id);
			},
			[dispatch]
		);

		const onClear = React.useCallback(async () => {
			return (dispatch as any)[modelName].clear();
		}, [dispatch]);

		// -------------------------------------------------
		// Response
		// -------------------------------------------------

		return { create: onCreate, update: onUpdate, destroy: onDelete, clear: onClear };
	};
}

export function createIndexHook<T = Record<string, unknown>>(modelName: string) {
	return function useIndex(obj: IndexOptions) {
		// -------------------------------------------------
		// Properties
		// -------------------------------------------------

		// states
		const [lastopt, setlastopt] = React.useState<IndexOptions>({});

		// store
		const store = useSelector((store: IRootState) => store);
		const dispatch = useDispatch<Dispatch>();

		// -------------------------------------------------
		// Effects
		// -------------------------------------------------

		React.useEffect(() => {
			if (JSON.stringify(lastopt) !== JSON.stringify(obj)) {
				setlastopt(obj);
			}
		}, [obj]);

		React.useEffect(() => {
			if (JSON.stringify(lastopt) === JSON.stringify(obj)) {
				(dispatch as any)[modelName].index(obj);
			}
		}, [lastopt]);

		// -------------------------------------------------
		// Response
		// -------------------------------------------------

		return (store as any)[modelName].list as IndexReturn<T>;
	};
}

export function createShowHook<T = Record<string, unknown>>(modelName: string) {
	return function useShow(indexId: string) {
		// -------------------------------------------------
		// Properties
		// -------------------------------------------------

		// states
		const [id, setId] = React.useState(indexId);

		// store
		const store = useSelector((store: IRootState) => store);
		const dispatch = useDispatch<Dispatch>();

		// -------------------------------------------------
		// Callbacks
		// -------------------------------------------------

		const onUpdate = React.useCallback(
			async (data) => {
				return (dispatch as any)[modelName].update({ id, data });
			},
			[dispatch]
		);

		const onDelete = React.useCallback(async () => {
			return (dispatch as any)[modelName].delete(id);
		}, [dispatch]);

		// -------------------------------------------------
		// Effects
		// -------------------------------------------------

		React.useEffect(() => {
			setId(indexId);
		}, [indexId]);

		React.useEffect(() => {
			(dispatch as any)[modelName].show(id);
		}, [id, store]);

		// -------------------------------------------------
		// Response
		// -------------------------------------------------

		return [
			(store as any)[modelName].data || false,
			onUpdate,
			onDelete,
		] as ShowReturn<T>;
	};
}

export default {
	index: createIndexHook,
	show: createShowHook,
	crud: createGeneralHook,
	all: <T = Record<string, unknown>>(name: string) => ({
		crud: createGeneralHook(name),
		index: createIndexHook<T>(name),
		show: createShowHook<T>(name),
	}),
};
