import { init, RematchRootState } from "@rematch/core";
import createPersistPlugin from "@rematch/persist";
import {
  InferableComponentEnhancerWithProps,
  MapStateToPropsParam,
} from "react-redux";
import storage from "redux-persist/lib/storage";

import models from "../index";
import controllerPlugin from "utils/requestFetch";

const persistPlugin = createPersistPlugin({
  version: 2,
  whitelist: ["outbound.counties"],
  key: "root",
  storage,
});

const store = init<any, any>({
  models,
  plugins: [persistPlugin, controllerPlugin({ blacklist: ["_persist"] })],
});

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type IRootState = RematchRootState<typeof models>;

declare module "react-redux" {
  interface Connect {
    <no_state = {}, TDispatchProps = {}, TOwnProps = {}>(
      mapStateToProps: null | undefined,
      mapDispatchToProps: (dispatch: Dispatch) => TDispatchProps
    ): InferableComponentEnhancerWithProps<TDispatchProps, TOwnProps>;

    <TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = {}>(
      mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
      mapDispatchToProps: (dispatch: Dispatch) => TDispatchProps
    ): InferableComponentEnhancerWithProps<
      TStateProps & TDispatchProps,
      TOwnProps
    >;
  }
}

export default store;
