const columns: any = (handleAction: (param: string, order: string) => void) => [
  {
    field: "countyUf",
    header: "Estado",
    name: "countyUf",
    align: "left",
    width: "10rem",
    sortable: true,
    onClickSort: (order: string) => handleAction("county_uf", order),
  },
  {
    field: "countyName",
    header: "Cidade",
    name: "countyName",
    align: "left",
    sortable: true,
    onClickSort: (order: string) => handleAction("county_name", order),
  },
  {
    field: "provider",
    header: "Provedor",
    name: "provider",
    align: "left",
    sortable: true,
    onClickSort: (order: string) => handleAction("provider", order),
    modifier: (props: string) => (props ? props : "-"),
  },
  {
    field: "layout",
    header: "Layout",
    name: "layout",
    align: "left",
    sortable: true,
    onClickSort: (order: string) => handleAction("layout", order),
    modifier: (props: string) => (props ? props : "-"),
  },
];
export default columns;
