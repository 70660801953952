// Packages
import React, { useState, useEffect, useCallback } from "react";
import {
  Title,
  Text,
  Table,
  FilterButton,
  Pagination,
  Modal,
} from "@seidor/orbit-ds-core";

// Styles
import style from "./style.module.scss";

// Parts
import Filter from "./counties/filter";
import MessageBlock from "components/MessageBlock";

// Hooks
import { useIndex } from "models/outbound/counties/hooks";
import columns from "./parts/columns";
import { handleFormatValues, handleRemoveItemFilter } from "./parts/utils";

// Assets
import emptySearch from "assets/svg/emptySearch.svg";

const OutboundView: React.FC<any> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [opened, setOpened] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [order, setOrder] = useState({
    param: "county_name",
    order: "asc" as "desc" | "asc",
  });
  const [limit] = useState(25);

  // Hooks
  const indexReturn: any = useIndex({
    page: currentPage,
    size: limit,
    sort: {
      order: order.order,
      param: order.param,
    },
    params: { ...filterValues },
  });
  const { data, totalPages, count } = indexReturn;

  // Functions
  const handleSubmitFilter = (items: any) => {
    const dataFormatted = {
      ...(items?.county_uf && !items?.county
        ? { county_uf: items.county_uf }
        : {}),
      ...(items?.county ? { county: items.county } : {}),
      ...(items?.layout ? { layout: items.layout } : {}),
      ...(items?.provider ? { provider: items.provider } : {}),
    };
    setCurrentPage(1);
    setFilterValues(dataFormatted);
    setOpened(false);
  };

  const handleCleanFilter = () => {
    setFilterValues({});
    setOpened(false);
  };

  const handleChangeOrder = useCallback((param: string, orderParam: string) => {
    setOrder({ param, order: orderParam as "asc" | "desc" });
  }, []);

  // Effects
  useEffect(() => {
    document.body.classList.add("lightTheme");

    return function cleanup() {
      document.body.classList.remove("lightTheme");
    };
  }, []);

  const tagsNames = {
    county_uf: "UF",
    county: "Município",
    layout: "Layout",
    provider: "Provedor",
  };

  return (
    <div className={style.sectionContainer}>
      <div className="identifierTag">Outbound</div>
      <div className={style.titleContainer}>
        <Title content="Relação de Municípios Atendidos" variant="h1" />
        <Text
          content="Veja abaixo a relação dos municípios homologados para emissão de nota fiscal de serviço no produto 4Tax Cloud Outbound. Caso o município que você busca não se encontre na listagem, entre em contato com a área comercial da Seidor."
          size="large"
        />
      </div>
      {console.log("filterValues", filterValues)}
      <div className={style.tableContainer}>
        <div className={style.headerTable}>
          <div className={style.headerTableLeft}>
            <FilterButton
              label="Filtrar"
              onClick={() => setOpened(true)}
              tags={handleFormatValues(filterValues, tagsNames)}
              onClickRemove={(item: any) => {
                handleRemoveItemFilter(item, filterValues, setFilterValues);
              }}
            />
          </div>
          <div>
            <Text
              content="Total de Municípios Disponíveis: "
              size="medium"
              weight="bold"
            />
            <span className={style.amountCounties}>{count || 0}</span>
          </div>
        </div>
        <Table
          columns={columns(handleChangeOrder)}
          rows={data}
          rowKey="id"
          emptyMessage={
            <MessageBlock
              title="Nenhum resultado encontrado"
              description={[
                "Infelizmente não encontramos o que procurava :/",
                <br key="notfound" />,
                "Tente refazer sua pesquisa ou limpe seus filtros.",
              ]}
              image={emptySearch}
              buttonAction={handleCleanFilter}
              buttonText="Limpar Filtros"
              buttonVariant="underlinedText"
            />
          }
        />
        {data && data.length > 0 && (
          <div className={style.footerTable}>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onSelectPage={setCurrentPage}
            />
          </div>
        )}
      </div>
      <Modal
        open={opened}
        title="Filtros"
        subtitle="Selecione os campos abaixo para aperfeiçoar a busca"
        allowClosableOutsideClick
        onClose={() => setOpened(false)}
      >
        <Filter
          handleSubmit={handleSubmitFilter}
          handleCleanFilters={handleCleanFilter}
          filterValues={filterValues}
        />
      </Modal>
    </div>
  );
};

export default OutboundView;
