// Utils
import hookFactory from "../../../utils/factory/hook";

// Interfaces
import IModel from "./types";

// Create all hooks necessary
const all = hookFactory.all<IModel>("outbound.counties");

// Exports
export default all;
export const useIndex = all.index;
export const useShow = all.show;
export const useCrud = all.crud;
