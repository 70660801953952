// Packages
import React from "react";
import { Route, Switch } from "react-router-dom";

// Parts
import OutboundView from "views/outbound";
import OutboundViewCounties from "views/outbound/counties";

const routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={OutboundView} />
      <Route exact path="/outbound" component={OutboundView} />
      <Route exact path="/outbound/counties" component={OutboundViewCounties} />
        
      {/* <Route component={NotFound} /> */}
    </Switch>
  );
};

export default routes;
