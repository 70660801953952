// -------------------------------------------------
// Genral
// -------------------------------------------------
import generalLocation from "./general/location/model";

// -------------------------------------------------
// Outbound
// -------------------------------------------------
import outboundCounties from "./outbound/counties/model";

const modules = {
  outboundCounties,
  generalLocation,
};

export default modules;
