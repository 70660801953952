// Packages
import React, { useEffect } from "react";

// Hooks
import { useHistory } from "react-router-dom";

// Images
import logo from "assets/svg/logoSimple.svg";
import logoInverted from "assets/svg/logoInverted.svg";

// Types
import { IHeaderProps } from "./types";

// Style
import style from "./style.module.scss";

const Header = (props: IHeaderProps) => {
  const { scroll } = props;
  const history = useHistory();

  return (
    <header
      className={`${style.headerBackground} ${scroll > 0 && style.onScroll}`}
    >
      <div className={style.headerContent}>
        <img
          src={document.body.className === "darkTheme" ? logoInverted : logo}
          alt="logo orbit"
        />
        <nav>
          <ul className={style.nav_ul}>
            <li>Home</li>
            <li>Módulos</li>
            <li>Contato</li>
            <li>Quem Somos</li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
export default Header;
