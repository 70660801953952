import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@seidor/orbit-ds-core";

import "./index.css";

// Containers
import Store from "./models/store";
import App from "./App";

// -------------------------------------------------
// General
// -------------------------------------------------
import "utils/language/i18n";
import "css/index.scss";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <>
    <BrowserRouter>
      <Provider store={Store}>
        <ThemeProvider
          themeName="OrbitTheme"
          language={
            localStorage.getItem("i18nextLng") === "es" ? "es-CO" : "pt-BR"
          }
        >
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </>,
  document.getElementById("root")
);

serviceWorker.unregister();