/* eslint-disable no-mixed-operators */

import Store from "../../models/store";

export default (err: any) => {
	const url = (err.response && err.response.config.url) || "";
	if (
		err &&
		err.response &&
		err.response.status === 401 &&
		err.response.config &&
		!url.includes("/logout")
	) {
		Store.dispatch.login.logout();
		// toast({
		// 	position: "top-end",
		// 	timer: 5000,
		// 	title: "Sessão expirada!",
		// 	type: "attention",
		// 	content: "Sua sessão expirou, favor autenticar a sua conta novamente.",
		// });
	}

	if (err && err.response && err.response.status === 429) {
		// toast({
		// 	position: "top-end",
		// 	timer: 5000,
		// 	type: "attention",
		// 	title: "Ops!",
		// 	content:
		// 		"Muitas requisições foram feitas em um curto período de tempo, favor aguardar.",
		// });
	}

	if (err && err.response && err.response.status === 413) {
		// toast({
		// 	position: "top-end",
		// 	timer: 5000,
		// 	type: "error",
		// 	title: "Ação bloqueada",
		// 	content:
		// 		"Arquivo enviado é grande, o limite máximo é 1MB, favor enviar novamente.",
		// });
	}

	if (
		err &&
		err.response &&
		(err.response.status === 502 || err.response.status === 500) &&
		err.response.config &&
		!url.includes("/users")
	) {
		// toast({
		// 	position: "top-end",
		// 	timer: 5000,
		// 	type: "attention",
		// 	title: "Ação não concluída",
		// 	content: "Nossos servidores estão instáveis, favor aguardar.",
		// });
	}

	if (err && err.response && err.response.status === 403 && !url.includes("/user")) {
		const message =
			err.response && err.response.data && err.response.data.customErrorMessage
				? err.response.data.customErrorMessage
				: "Você não tem autorização para acessar este módulo, favor entrar em contato com o responsável.";
		const details = err.response && err.response.data ? err.response.data.path : {};
		// toast({
		// 	position: "top-end",
		// 	timer: 5000,
		// 	type: "attention",
		// 	title: "Módulo bloqueado",
		// 	content: message,
		// });
		Store.dispatch.location.fetchRedirectLocationError({
			status: true,
			path: "/unauthorized",
			details,
			message,
		});
	}
};
