// Packages
import React, { useEffect, useState } from "react";
import { Form, Dropdown, Button, IOptionProps } from "@seidor/orbit-ds-core";
import { useDispatch } from "react-redux";

// Components
import Grid from "components/Grid";

// Types
import { IDispatch } from "models/outbound/counties/types";
import { IDispatch as IDispatchLocation } from "models/general/location/types";

interface IProps {
  handleSubmit: any;
  handleCleanFilters: any;
  filterValues: any;
}

function RenderFormFilter({
  filterValues,
  handleSubmit,
  handleCleanFilters,
}: IProps) {
  const [optionsLayouts, setOptionsLayouts] = useState<IOptionProps[]>([]);
  const [optionsProviders, setOptionsProviders] = useState<IOptionProps[]>([]);
  const [optionsUf, setOptionsUf] = useState<IOptionProps[]>([]);
  const [selectedUf, setSelectedUf] = useState<string | undefined>();
  const [optionsCities, seOptionsCities] = useState<IOptionProps[]>([]);

  // Dispatch
  const { "outbound.counties": dispatch } = useDispatch<IDispatch>();
  const { "general.location": dispatchAddress } =
    useDispatch<IDispatchLocation>();

  // -------------------------------------------------
  // Callbacks
  // -------------------------------------------------

  const handleSubmitFilterForm = React.useCallback(
    (values) => {
      console.log(values);

      handleSubmit(values);
      return true;
    },
    [handleSubmit]
  );

  const handleConvertOptions = (arr: any[]) =>
    arr?.map((item: string) => {
      return {
        text: item,
        value: item,
      };
    }) as any;

  const handleGetCitiesFromUf = React.useCallback(
    async (uf) => {
      const res: any = await dispatchAddress.getCity(uf);
      if (res) {
        setSelectedUf(uf);
        seOptionsCities(
          res?.map((item: { name: string; code: string }) => {
            return {
              text: item?.name,
              value: item?.code,
            };
          }) as any
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      const responseLayout: any = await dispatch.getLayoutDefinition();

      const responseProvider: any = await dispatch.getProviderDefinition();

      const responseUF: any = await dispatchAddress.getUF();

      if (responseLayout) {
        setOptionsLayouts(handleConvertOptions(responseLayout?.data));
      }

      if (responseProvider) {
        setOptionsProviders(handleConvertOptions(responseProvider?.data));
      }

      if (responseUF) {
        setOptionsUf(
          responseUF?.map((item: { uf: string; cuf: string }) => {
            return {
              text: item?.uf,
              value: item?.uf,
            };
          }) as any
        );
      }
    })();
  }, [dispatch]);

  // Render
  return (
    <Form onSubmit={handleSubmitFilterForm}>
      <Grid.Row>
        <Grid.Column>
          <Dropdown
            placeholder="Selecione..."
            options={optionsUf}
            label="Estado"
            name="county_uf"
            onChange={handleGetCitiesFromUf}
            loadingStatus={optionsUf?.length === 0 ? "loading" : undefined}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            placeholder="Selecione..."
            options={optionsCities}
            label="Cidade"
            name="county"
            disabled={!selectedUf}
            loadingStatus={
              selectedUf && optionsCities?.length === 0 ? "loading" : undefined
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Dropdown
            placeholder="Selecione..."
            options={optionsLayouts}
            label="Layout"
            name="layout"
            loadingStatus={optionsLayouts?.length === 0 ? "loading" : undefined}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            placeholder="Selecione..."
            options={optionsProviders}
            label="Provedor"
            name="provider"
            loadingStatus={
              optionsProviders?.length === 0 ? "loading" : undefined
            }
          />
        </Grid.Column>
      </Grid.Row>
      <div className="formActions">
        <Button
          label="Limpar filtros"
          variant="underlinedText"
          onClick={handleCleanFilters}
        />
        <Button label="Filtrar" icon="fe-filter" type="submit" />
      </div>
    </Form>
  );
}
export default RenderFormFilter;
