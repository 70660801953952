/* eslint-disable no-undef */
import axios from "axios";
// criando url base para requisições
const url = process.env.REACT_APP_URL_API_ORBIT;
const SUCCESS_STATUS_LOG = 3;

// configurando o typo de requisição e rota base
export const client = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-store , no-cache",
    Accept: "application/json",
  },
});

client.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export const addHeaders = (headers: any, newHeaders?: any) => {
  headers = Object.assign(headers, newHeaders);
};

client.interceptors.request.use(
  (config) => {
    config.url = `${url}${config.url}`;

    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response: any) => {
    const errorResponse =
      response.data &&
      Array.isArray(response.data.statusLog) &&
      response.data.statusLog[0].type !== SUCCESS_STATUS_LOG;
    if (response.data) {
      const headers: any = {};

      addHeaders(headers);
    }

    return errorResponse
      ? Promise.reject(response)
      : Promise.resolve(response.data);
  },
  (error) => Promise.reject(error)
);