import { Model } from "@rematch/core";

export default (name: string) =>
	({
		// -------------------------------------------------
		// Config
		// -------------------------------------------------

		name,

		// -------------------------------------------------
		// Reducers
		// -------------------------------------------------

		reducers: {
			setModule: (state, module) => {
				return {
					...state,
					module,
				};
			},
			setLoading: (state, loading?: boolean) => {
				return {
					...state,
					loading: loading === undefined ? !state.loading : loading,
					errors: state.errors,
				};
			},
			addErrors: (state, errors: any[]) => {
				return {
					...state,
					loading: state.loading,
					errors: [...state.errors, ...errors],
				};
			},
			setErrors: (state, errors: any[]) => {
				return {
					...state,
					loading: state.loading,
					errors,
				};
			},
			clearErrors: (state) => {
				return {
					loading: state.loading,
					errors: [],
				};
			},
		},

		// -------------------------------------------------
		// State
		// -------------------------------------------------

		state: {
			module: undefined as { id: string; label: string } | undefined,
			loading: false,
			errors: [],
		},
	} as Model);
