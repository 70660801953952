/* eslint-disable no-console */
// Interfaces
import { Plugin } from "@rematch/core";
import { FetchConfig, FetchPayload } from "./types";

// Helpers
import Controller from "./models";
import validateConfig from "./utils";
import { client } from "../client";
import HandleErrors from "../handleErrors";

type generic = Record<string, unknown>;

export default (config?: FetchConfig): Plugin => {
  // Validate plugin
  validateConfig(config);
  const controllerName = config?.controllerName || "controller";

  // -------------------------------------------------
  // Config response
  // -------------------------------------------------

  return {
    config: {
      models: {
        controller: Controller(controllerName),
      },
    },
    onModel(model, rematchStore): void {
      // ignore controller
      if (model.name === controllerName) return;

      // ignore items not in whitelist
      if (config?.whitelist && !config.whitelist.includes(model.name)) return;

      // ignore items in blacklist
      if (config?.blacklist && config.blacklist.includes(model.name)) return;

      const actions = (rematchStore.dispatch as generic)[model.name] as generic;
      // map effects
      Object.keys(actions).forEach((key) => {
        // only map effects
        if ((actions[key] as generic).isEffect !== true) return;

        const actionType = `${model.name}/${key}`;

        // ignore items not in whitelist
        if (config?.whitelist && !config.whitelist.includes(actionType)) return;

        // ignore items in blacklist
        if (config?.blacklist && config.blacklist.includes(actionType)) return;

        // copy orig effect pointer
        const origEffect = actions[key] as (
          ...props: generic[]
        ) => FetchPayload;
        const controller = (rematchStore.dispatch as generic)[
          controllerName
        ] as generic;

        // wrapper
        const wrapper = async (...props: generic[]) => {
          // waits for dispatch function to finish before calling "hide"
          const preRequest = await origEffect(...props);
          const request = {
            ...preRequest,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              ...preRequest?.headers,
            },
          } as FetchPayload;

          // make request
          if (request && typeof request === "object" && request.endpoint) {
            if (request.loader === undefined || request.loader)
              await (
                controller.setLoading as (state: boolean) => Promise<void>
              )(true);

            const method = request.method || "get";
            const data = request.before
              ? await request.before(request.data || {})
              : request.data || {};

            try {
              const response = await (client[method] as any)(
                request.endpoint,
                ["get"].includes(method) ? request : JSON.stringify(data),
                !["get"].includes(method) ? request : undefined
              );

              // Get data
              const rawResponseData = request.retrieveOn
                ? request.retrieveOn
                    .split(".")
                    .reduce((prev, curr) => prev[curr], response)
                : response;
              const responseData = request.filter
                ? await request.filter(rawResponseData)
                : rawResponseData;

              if (request.after) await request.after(responseData);

              // Save to state
              if (request.dispatch)
                await (actions[request.dispatch] as (_: unknown) => void)(
                  responseData
                );

              // Finish loading
              if (request.loader === undefined || request.loader)
                await (
                  controller.setLoading as (state: boolean) => Promise<void>
                )(false);

              return responseData;
            } catch (error: any) {
              if (process.env.NODE_ENV !== "production") console.log(error);
              let errorMessage =
                "Houve uma falha na requisição, favor contactar o suporte.";
              if (request.catch) await request.catch(error as generic);
              if (error && error.response) {
                // medida provisória enquanto não ocorre a padronização de resposta do back
                if (!error?.response?.data?.errors) {
                  errorMessage =
                    error?.response?.data?.message ||
                    request.errorMessage ||
                    "Houve um problema ao completar a solicitação";
                } else {
                  let multipleErrors = "";
                  if (error?.response?.data?.errors[0]) {
                    if (error?.response?.data?.errors[0].msg) {
                      multipleErrors = error?.response?.data?.errors[0].msg;
                    } else if (error?.response?.data?.errors[0].message) {
                      multipleErrors = error?.response?.data?.errors[0].message;
                    } else {
                      multipleErrors = error?.response?.data?.errors[0];
                    }
                  }
                  errorMessage = multipleErrors;
                }
              }

              if (request.loader === undefined || request.loader)
                await (
                  controller.setLoading as (state: boolean) => Promise<void>
                )(false);
              await (controller.addErrors as (obj: string[]) => Promise<void>)([
                errorMessage,
              ]);

              HandleErrors(error);
            }
            return undefined;
          }

          return request;
        };

        wrapper.isEffect = true;

        // replace existing effect with new wrapper
        actions[key] = wrapper;
      });
    },
  };
};
