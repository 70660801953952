// Package
import createModel from "../../../utils/factory/model";

// Interface
import IOutboundCountiesModel from "./types";

const baseUrl = "/dfeservice/api/nfse";

export default createModel<IOutboundCountiesModel>({
  name: "outbound.counties",
  endpoint: {
    general: `${baseUrl}/specs`,
  },

  effects: () => ({
    async getLayoutDefinition() {
      return {
        endpoint: `${baseUrl}/getDefinitions?column=layout`,
      };
    },
    async getProviderDefinition() {
      return {
        endpoint: `${baseUrl}/getDefinitions?column=provider`,
      };
    },
  }),
});
