// Interfaces
import { Model } from "@rematch/core";
import Config, { ModelState, genericModel } from "./types";

// Store
import store from "../../../models/store";
import { handleCache, handleAfter, prepareMock } from "./utils";

export default function createModel<T = genericModel>(config: Config) {
	// cache
	const cacheIndex = handleCache(config, "index");
	const cacheShow = handleCache(config, "show");
	const cacheCreate = handleCache(config, "create");
	const cacheUpdate = handleCache(config, "update");
	const cacheDelete = handleCache(config, "delete");

	// mock
	const mock = prepareMock(config.mock);

	return {
		// -------------------------------------------------
		// Properties
		// -------------------------------------------------

		name: config.name,
		state: {
			list: {
				data: [] as T[],
				page: 1,
				size: 20,
			},
			data: {} as T,
			...(config.state || {}),
		} as ModelState<T>,

		// -------------------------------------------------
		// Reducers
		// -------------------------------------------------

		reducers: {
			setListInfo: (state, payload) => {
				return {
					...state,
					list: {
						data: state.list.data,
						...payload,
					},
				};
			},
			setList: (state, payload) => {
				return {
					...state,
					list: {
						data: payload,
					},
				};
			},
			setData: (state, payload) => {
				return {
					...state,
					data: payload,
				};
			},
			clearAll: () => {
				return {
					list: {
						data: [] as T[],
					},
					data: {} as T,
					...(config.state || {}),
				};
			},
			...(config.reducers || {}),
		},

		// -------------------------------------------------
		// Effects
		// -------------------------------------------------

		effects: (dispatch: any) => ({
			async index(data?) {
				const state = (store.getState() as any)[config.name] as ModelState<T>;
				if (mock) {
					const response = mock.index(data);
					await dispatch[config.name].setListInfo(response);
					await handleAfter(dispatch, data, response, config, "index");

					return response;
				}

				return {
					endpoint: cacheIndex.endpoint(data),
					params: {
						size: data?.size || state.list.size,
						page: data?.page || state.list.page,
						expand: data?.expand || state.list.expand,
						sortParam: data?.sort?.param || state.list.sort?.param,
						sortOrder: data?.sort?.order || state.list.sort?.order,
						...(data.params || {}),
					},
					headers: data.headers || undefined,
					message: cacheIndex.message(data),
					errorMessage: cacheIndex.errorMessage(data),
					after: async (response: Record<string, unknown>) => {
						await dispatch[config.name].setListInfo(response);
						await handleAfter(dispatch, data, response, config, "index");
					},
				};
			},

			async show(id) {
				if (mock) {
					const response = mock({ id });
					await dispatch[config.name].setData(response);
					await handleAfter(dispatch, { id }, response, config, "show");

					return response;
				}

				return {
					endpoint: cacheShow.endpoint({ id }),
					message: cacheShow.message({ id }),
					errorMessage: cacheShow.errorMessage({ id }),
					after: async (response: Record<string, unknown>) => {
						await dispatch[config.name].setData(response);
						await handleAfter(dispatch, { id }, response, config, "show");
					},
				};
			},

			async create(data) {
				if (mock) {
					const response = mock(data);
					await dispatch[config.name].setData(response);
					await handleAfter(dispatch, data, response, config, "create");

					return response;
				}

				return {
					endpoint: cacheCreate.endpoint(data),
					method: "post",
					data,
					message: cacheCreate.message(data),
					errorMessage: cacheCreate.errorMessage(data),
					after: async (response: Record<string, unknown>) => {
						await dispatch[config.name].setData(response);
						await handleAfter(dispatch, data, response, config, "create");
					},
				};
			},

			async update(data: { id: string; data: Record<string, unknown> }) {
				if (mock) {
					const response = mock({ id: data.data, ...data.data });
					await dispatch[config.name].setData(response);
					await handleAfter(dispatch, data, response, config, "update");

					return response;
				}

				return {
					endpoint: cacheUpdate.endpoint(data),
					method: "put",
					data: data.data,
					message: cacheUpdate.message(data),
					errorMessage: cacheUpdate.errorMessage(data),
					after: async (response: Record<string, unknown>) => {
						await dispatch[config.name].setData(response);
						await handleAfter(dispatch, data, response, config, "update");
					},
				};
			},

			async delete(data: { id: unknown; [key: string]: unknown }) {
				if (mock) {
					const response = mock(data);
					await dispatch[config.name].setData(response);
					await handleAfter(dispatch, data, response, config, "delete");

					return response;
				}

				return {
					endpoint: cacheDelete.endpoint(data),
					method: "delete",
					data: data.data,
					params: data.params,
					message: cacheDelete.message(data),
					headers: data.headers || undefined,
					errorMessage: cacheDelete.errorMessage(data),
					after: async () => {
						await dispatch[config.name].setData(false);
						await handleAfter(dispatch, data, {}, config, "delete");
					},
				};
			},

			async clear() {
				dispatch[config.name].clearAll();
			},

			...((config.effects && config.effects(dispatch)) || {}),
		}),
	} as Model;
}
