import styles from "../style.module.scss";

export const spacingOptions = {
	small: styles.grid_row_spacing_y_1,
	medium: styles.grid_row_spacing_y_2,
	large: styles.grid_row_spacing_y_3,
};

// COLUMN

export const columnCountOptions = {
	0: styles.grid_column_count_0,
	1: styles.grid_column_count_1,
	2: styles.grid_column_count_2,
	3: styles.grid_column_count_3,
	4: styles.grid_column_count_4,
	5: styles.grid_column_count_5,
	6: styles.grid_column_count_6,
};

export const columnSpacingXOptions = {
	small: styles.grid_column_spacing_x_1,
	medium: styles.grid_column_spacing_x_2,
	large: styles.grid_column_spacing_x_3,
};

export const columnWidthsOptions: any = {
	1: styles.grid_column_widths_1,
	2: styles.grid_column_widths_2,
	3: styles.grid_column_widths_3,
	4: styles.grid_column_widths_4,
	5: styles.grid_column_widths_5,
	6: styles.grid_column_widths_6,
	7: styles.grid_column_widths_7,
	8: styles.grid_column_widths_8,
	9: styles.grid_column_widths_9,
	10: styles.grid_column_widths_10,
	11: styles.grid_column_widths_11,
	12: styles.grid_column_widths_12,
};
