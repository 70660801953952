export const handleFormatValues = (values: any, matches: any) => {
  return Object.entries(values)
    .map((objValue: any) => {
      const findLabelMatch =
        objValue[1]?.length > 0 &&
        Object.entries(matches).find((dataObj: any) => {
          return dataObj[0] === objValue[0];
        });
      let newObj;
      if (findLabelMatch) {
        newObj = { label: findLabelMatch[1], value: findLabelMatch[0] };
      }
      return newObj;
    })
    .filter((y) => y !== undefined);
};

export const handleRemoveItemFilter = (
  item: any,
  values: { [key: string]: any },
  setData: (values: any) => void
) => {
  const data = Object.keys(values).reduce((object, key) => {
    const response: any = object;
    if (key !== item) {
      response[key] = values[key];
    }
    return response;
  }, {});
  setData(data);
};

export const fastSearchProcessOptions = [
  {
    key: "county_uf",
    value: "county_uf",
    text: "UF",
  },
  {
    key: "county_name",
    value: "county_name",
    text: "Cidade",
  },
  {
    key: "layout",
    value: "layout",
    text: "Layout",
  },
  {
    key: "provider",
    value: "provider",
    text: "Provedor",
  },
];
