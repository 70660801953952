// Packages
import React, { useLayoutEffect, useState } from "react";

// Parts
import { columnCountOptions, columnSpacingXOptions, columnWidthsOptions } from "./utils";

// Styles
import styles from "./style.module.scss";

// Types
import { IColumn } from "./types";

export default ({
	className = "",
	children,
	spacing = "small",
	sizes = "auto",
	maxColumnCount = 0,
}: IColumn) => {
	// Responsive
	function useWindowSizeGlobal() {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
			function updateSize() {
				const { offsetWidth, offsetHeight }: any = document.body;
				setSize([offsetWidth, offsetHeight]);
			}
			window.addEventListener("resize", updateSize);
			updateSize();
			return () => window.removeEventListener("resize", updateSize);
		}, []);
		return size;
	}
	const responsive = useWindowSizeGlobal();

	// Widths
	const sizeComputer = sizes[0];
	const sizeTablet = sizes.length >= 1 ? sizes[1] : null;
	const sizePhone = sizes.length >= 2 ? sizes[2] : null;
	const getSize = (): string => {
		let sizeClasses = `${columnWidthsOptions[sizeComputer]}`;

		if (responsive[0] <= 480) {
			sizeClasses = sizePhone ? `${columnWidthsOptions[sizePhone]}` : "";
		} else if (responsive[0] <= 1023) {
			sizeClasses = sizeTablet ? `${columnWidthsOptions[sizeTablet]}` : "";
		}

		return sizes === "auto" ? styles.grid_column_widths_auto : sizeClasses;
	};

	const classNameConcat = `${columnCountOptions[maxColumnCount]} ${
		columnSpacingXOptions[spacing]
	} ${getSize()}`;

	return (
		<div className={`${classNameConcat} ${className} ${styles.grid_column_wrapper}`}>
			{children}
		</div>
	);
};
